import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import HeaderLogo from './HeaderLogo'

const Header = ({ authed = false }) => {
  const location = useLocation()
  return (
    <>
      {location.pathname !== '/login' && (
        <div className='container'>
          <div className='row'>
            <div className='col-12 '>
              <nav className='navbar navbar-expand-xl navbar-light bg-light fixed-top boxxyShadow container-fluid'>
                <div className='container'>
                  <a
                    className='logo-img nonResponsive'
                    href='https://www.papersource.com/'
                  >
                    {/* <HeaderLogo /> */}

                    <img
                      title='Discover Where You’d Like to Work'
                      alt='Discover Where You’d Like to Work'
                      data-displaymode='Original'
                      width={300}
                      className='img-fluid'
                      src='/img/ps-logo-4-2023.png'
                    />
                  </a>
                  <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarCollapse'
                    aria-controls='navbarCollapse'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                  >
                    {' '}
                    <span className='navbar-toggler-icon'></span>{' '}
                  </button>
                  <div className='collapse navbar-collapse' id='navbarCollapse'>
                    <ul className='navbar-nav ms-auto'>
                      {location.pathname !== '/' &&
                      location.pathname !== '/internal' ? (
                        <li className='nav-item'>
                          {' '}
                          <Link
                            className='nav-link'
                            to={authed ? '/internal' : '/'}
                          >
                            Careers
                          </Link>{' '}
                        </li>
                      ) : (
                        <li className='nav-item nav-link active'>Careers</li>
                      )}

                      {!authed &&
                        (location.pathname !== '/inside-ps' ? (
                          <li className='nav-item'>
                            {' '}
                            <Link
                              className='nav-link'
                              to={authed ? '/internal/inside-ps' : '/inside-ps'}
                            >
                              Inside Paper Source
                            </Link>{' '}
                          </li>
                        ) : (
                          <li className='nav-item nav-link active'>
                            Inside Paper Source
                          </li>
                        ))}

                      {!authed &&
                        (location.pathname !== '/locations' ? (
                          <li className='nav-item'>
                            <Link className='nav-link' to='/locations'>
                              Locations
                            </Link>
                          </li>
                        ) : (
                          <li className='nav-item nav-link active'>
                            Locations
                          </li>
                        ))}

                      {location.pathname !== '/retail-jobs' &&
                      location.pathname !== '/internal/retail-jobs' ? (
                        <li className='nav-item'>
                          {' '}
                          <Link
                            className='nav-link'
                            to={
                              authed ? '/internal/retail-jobs' : '/retail-jobs'
                            }
                          >
                            {authed ? 'Retail Opportunities' : 'Retail Jobs'}
                          </Link>{' '}
                        </li>
                      ) : (
                        <li className='nav-item nav-link active'>
                          {authed ? 'Retail Opportunities' : 'Retail Jobs'}
                        </li>
                      )}

                      {location.pathname !== '/corporate-jobs' &&
                      location.pathname !== '/internal/home-office-jobs' ? (
                        <li className='nav-item'>
                          {' '}
                          <Link
                            className='nav-link'
                            to={
                              authed
                                ? '/internal/home-office-jobs'
                                : '/corporate-jobs'
                            }
                          >
                            {authed
                              ? 'Home Office Opportunities'
                              : 'Corporate Jobs'}
                          </Link>{' '}
                        </li>
                      ) : (
                        <li className='nav-item nav-link active'>
                          {authed
                            ? 'Home Office Opportunities'
                            : 'Corporate Jobs'}
                        </li>
                      )}
                    </ul>
                  </div>
                  <div>
                    {authed && (
                      <Button
                        class='btn btn-outline-dark btn-sm col-lg-12 text-uppercase font-weight-bold'
                        href='/logout'
                      >
                        Log Out
                      </Button>
                    )}
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

Header.defaultProps = {
  title: 'Task',
}

Header.propTypes = {
  title: PropTypes.string,
}

export default Header
