import React from 'react'
import { Link } from 'react-router-dom'

const HomePageSectionTwo = ({ authed }) => {
  return (
    <div className='container'>
      <div className='row align-items-center'>
        <div className='col-xl-5 col-lg-5 col-md-12  col-sm-12 col-xs-12'>
          <img
            className='img-fluid'
            title='Take a Look Inside Paper Source'
            alt='Take a Look Inside Paper Source'
            src='/img/ps/Take-A-Look-Inside-PS-700x483.jpg'
          />
        </div>
        <div className='col-lg-7 col-md-12 col-sm-12'>
          <h3>
            <strong>Take a Look Inside Paper Source</strong>
          </h3>
          <p>
            Connection is at the heart of all that we do and we believe that a
            human touch makes everything better. With that, we strive to create
            easy-to-shop spaces with unique character, worldly treasures and
            vibrant associates who love what they do. We bring high-touch, human
            interaction to every aspect of the customer journey – from social
            impact within our communities to special touches that live in our
            product – and we believe in the infectious nature of these
            thoughtful interactions.
          </p>
          <Link
            className='btn btn-outline-dark'
            to={authed ? '/internal/inside-ps' : '/inside-ps'}
          >
            Learn more about us
          </Link>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className='row align-items-center'>
        <div className='col-lg-7 col-md-12 col-sm-12'>
          <h3>
            <strong>Discover Where You’d Like to Work</strong>
          </h3>
          <p>
            Craft a career that you love. Join a team filled with passionate
            creatives and work in a visually inspiring environment for a
            design-focused brand that brings beauty and joy into meaningful life
            moments, big and small. We also hire for Corporate positions in Chicago.
          </p>
          <Link className='btn btn-outline-dark' to={'/locations'}>
            More about our locations
          </Link>
        </div>
        <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 align-middle'>
          <img
            title='Discover Where You’d Like to Work'
            alt='Discover Where You’d Like to Work'
            data-displaymode='Original'
            className='img-fluid'
            src='/img/ps/Discover-Where-Youd-Like-to-Work-700x483.jpg'
          />
          <div className='spacer-div'>&nbsp;</div>
        </div>
      </div>
    </div>
  )
}

export default HomePageSectionTwo
