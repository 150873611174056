import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const LocationsPage = ({ authed }) => {
  useEffect(() => {
    document.title = 'Locations'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='navClearingExtended'></div>
      <div className='container'>
        <div className='row align-contenmt-center'>
          <div className='col-lg-12'>
            <h1>
              <strong>Discover Where You&apos;d Like to Work</strong>
            </h1>
            <p className='lead'>
              Craft a career that you love. Join a team filled with passionate
              creatives and work in a visually inspiring environment for a
              design- focused brand that brings beauty and joy into meaningful
              life moments, big and small. We also hire for Corporate positions in Chicago.
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>

      <div className='container'>
        <div className='row '>
          <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12'>
            <img
              className='img-fluid'
              width='100%'
              title='Paper Source Retail Stores'
              alt='Paper Source Retail Stores'
              src='/img/ps/Locations-Retail-Stores-700x467.jpg'
            />
          </div>
          <div className='col-lg-7 col-md-7 col-sm-12'>
            <h5>
              <strong>Retail</strong>
            </h5>
            <p>
              Our associates bring the Paper Source experience to life. The
              knowledge, passion and creative solutions that they offer is what
              connects us to our customers. We count on our associates to engage
              with our neighbors and community, as we encourage our stores to
              become the ‘happy place’ of the local communities through monthly
              walk-in crafting experiences, eye-catching designs and visual
              inspiration. If you are customer-service oriented and passionate
              about helping others, join our Paper Source retail teams today.
            </p>
            <Link
              className='btn btn-outline-dark'
              to={authed ? '/internal/retail-jobs' : '/retail-jobs'}
            >
              View Available Positions
            </Link>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className='row'>
          <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 col-xs-12'>
            <img
              title='Paper Source Home Office'
              alt='Paper Source Home Office'
              width='100%'
              data-displaymode='Original'
              className='img-fluid'
              src='/img/ps/Locations-Home-Office-700x467.jpg'
            />
            <div className='spacer-div'>&nbsp;</div>
          </div>
          <div className='col-lg-7 col-md-7 col-sm-12'>
            <h5>
              <strong>Home Office</strong>
            </h5>
            <p>
              Our Corporate team brings our nationwide stores into alignment
              under a strategically crafted vision. Team members passionately
              work behind the scenes to ensure smooth operations and consistent
              excellence at all customer touchpoints. Opportunities may include
              positions in Accounting, Customer Service, Digital/eCommerce,
              Finance, HR, IT, Marketing, Merchandising, Publishing, and Store
              Operations. If you are interested in working with a collaborative
              and creative team, we’re always seeking passionate and talented
              individuals seeking growth.
            </p>
            <Link
              className='btn btn-outline-dark'
              to={authed ? '/internal/home-office-jobs' : '/corporate-jobs'}
            >
              View Available Positions
            </Link>
          </div>
        </div>

        <br />
      </div>
    </>
  )
}

export default LocationsPage
